.error {
  height: 100vh;
  background-color: #121212;
}

.main {
  padding-top: 40px;
}

.errorTitle {
  font-size: 15px;
  text-align: center;
}

.errorText {
  margin-top: 24px;
  color: #ccc;
  font-size: 13px;
  text-align: center;
}

.errorLink {
  margin-top: 40px;
  text-align: center;
}

.link {
  color: var(--color-yellow);
}
